
// STATE
export const STETH_REVIEW = {
  SUCCESS: 'STETH_REVIEW.SUCCESS',
  FETCHING: 'STETH_REVIEW.FETCHING',
  ACTIVE: 'STETH_REVIEW.ACTIVE',
  RESET_DATA: 'STETH_REVIEW.RESET_DATA',
  ERROR: 'STETH_REVIEW.ERROR'
}

export const STETHIO_WAV_FILE = {
  SUCCESS: 'STETHIO_WAV_FILE.SUCCESS',
  FETCHING: 'STETHIO_WAV_FILE.FETCHING',
  ERROR: 'STETHIO_WAV_FILE.ERROR'
}

export const SUBMIT_STETH_REVIEW = {
  SUCCESS: 'SUBMIT_STETH_REVIEW.SUCCESS',
  SUBMITTING: 'SUBMIT_STETH_REVIEW.SUBMITTING',
  ERROR: 'SUBMIT_STETH_REVIEW.ERROR',
  TOASTER_SHOW: 'SUBMIT_STETH_REVIEW.TOASTER_SHOW'
}

export const STETHIO_STATUS_UPDATE = {
  SUCCESS: 'STETHIO_STATUS_UPDATE.SUCCESS',
  UPDATING: 'STETHIO_STATUS_UPDATE.UPDATING',
  ERROR: 'STETHIO_STATUS_UPDATE.ERROR'
}