import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI } from "../../Axios/axios";

const errorData = (status) => {
  return {
    type: types.STETHIO_WORKLIST.ERROR,
    payload: status,
  };
};

const errorOverdueCount = (status) => {
  return {
    type: types.STETHIO_OVERDUE_COUNT.ERROR,
    payload: status,
  };
};

export const isFetching = (status) => {
  return {
    type: types.STETHIO_WORKLIST.FETCHING,
    payload: status,
  };
};

export const resetData = (status) => {
  return {
    type: types.RESET_DATA,
    payload: status,
  };
};

export const overdueCount = () => {
  return (dispatch) => {
    dispatch(isFetching(true));
    CrpAPI.get(
      `steth/count`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(isFetching(false));
          dispatch({
            type: types.STETHIO_OVERDUE_COUNT.SUCCESS,
            payload: res.data.data,
          });
          dispatch(errorOverdueCount(false));
        }
      })
      .catch((err) => {
        dispatch(isFetching(false));
        dispatch(errorOverdueCount(true));
      });
  };
};


export const getStethIOWorklistData = (
  limit,
  page = "0",
  searchItem = "",
  worklistState = "INCOMING",
) => {
  let oder = "asc";
  let completed_oder = "asc";

  if (worklistState === "INCOMING") {
    return (dispatch) => {
      dispatch(resetData())
      dispatch(isFetching(true));
      // Dummy ----
      // dispatch(isFetching(false));
      // dispatch({
      //   type: types.STETHIO_WORKLIST.SUCCESS,
      //   payload: DUMMY_WORKLIST_DATA,
      // });
      // dispatch(errorData(false));
      // // Dummy ----

      CrpAPI.get(
        // `steth/worklist?limit=${limit}&offset=${page}&search=${searchItem}&timeRequest=${oder}&status=${worklistState}`,
        `steth/worklist?limit=${limit}&offset=${page}&search=${searchItem}&timeRequest=${oder}&status=${worklistState}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(isFetching(false));
            dispatch({
              type: types.STETHIO_WORKLIST.SUCCESS,
              payload: res.data.data,
            });
            dispatch(errorData(false));
          }
        })
        .catch((err) => {
          dispatch(isFetching(false));
          dispatch(errorData(true));
        });
    };
  }
  if (worklistState === "COMPLETED") {
    return (dispatch) => {
      dispatch(resetData())
      dispatch(isFetching(true));
      CrpAPI.get(
        `steth/worklist?limit=${limit}&offset=${page}&search=${searchItem}&timeRequest=${oder}&status=${worklistState}&timeSubmit=${completed_oder}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
        .then((res) => {
          dispatch(isFetching(false));
          if (res.status === 200) {
            dispatch({
              type: types.STETHIO_WORKLIST.SUCCESS,
              payload: res.data.data,
            });
            dispatch(errorData(false));
          }
        })
        .catch((err) => {
          dispatch(isFetching(false));
          dispatch(errorData(true));
        });
    };
  }
};
