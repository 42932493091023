import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import axios from "axios";
import { Grid, Box, Typography } from "@material-ui/core";
import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";

import MuiTypography from "../../Components/Shared/Typography/MuiTypography";

import EkgPatientInformation from "../../Components/EkgPatientInformation/EkgPatientInformation";
import PatientGraphInfo from "../../Components/PatientGraphInfo/PateintGraphInfo";
import EkgCharts from "../../Components/chart/EkgCharts/EkgCharts";

import Toast from "../../Components/Toast/Toast";

import Spectrogram from '../../Components/Spectrogram/Spectrogram';
import StethIOReviewForm from "../../Components/StethIOReviewForm/StethIOReviewForm";

import { getStethIOReviewData, stethIOReviewSubmit, showToastHandler, stethIOUpdateStatus } from "../../Store/StethIOReview/StethIOReviewAction";

import { getEKGAlgorithmDeterminationDisplayText } from "../../consts/recordingMaps";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";
import { useStyles } from "./StethIOReviewStyles";

const StethIOReview = () => {
  const classes = useStyles();
  const { id, state } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();


  const stethIOReview = useSelector((state) => state.stethIOReview);
  const patientData = stethIOReview.reviewData && stethIOReview.reviewData.patientDetail;
  const reviewDetail = stethIOReview.reviewData && stethIOReview.reviewData.reviewDetail;
  const recordingSample = stethIOReview.reviewData && stethIOReview.reviewData.recordingSample;
  const isLoading = stethIOReview.isLoading
  const error = stethIOReview.error
  const stethIOWavFileURL = useSelector((state) => state.stethIOReview.stethIOWavFileURL);
  const wavFileLoading = stethIOReview.wavFileIsLoading
  const wavFileError = stethIOReview.wavFileError
  const showToaster = stethIOReview.showToaster
  const reviewSubmittingError = stethIOReview.reviewSubmittingError

  useEffect(() => {
    // return () => {
    dispatch(getStethIOReviewData(id, state));
    // };
  }, [dispatch, id]);



  const submitStethIOReview = (data) => {
    let formData = {
      "notes": data.note,
      "normalHeart": data.normalHeart,
      "normalLung": data.normalLung,
      "normalIntestine": data.normalIntestine,
      "patientId": parseInt(patientData.id),
      "memberFirstName": patientData.firstName,
      "memberLastName": patientData.lastName
    }
    dispatch(stethIOReviewSubmit(formData, history))
  }

  const cancelStehIOReview = (data) => {
    const CANCEL = state !== 'COMPLETED' // (reviewDetail.notes === '' || reviewDetail.notes === null)
    if (CANCEL) {
      dispatch(stethIOUpdateStatus(patientData.id, 'cancel', history))
    } else {
      history.push(`/clinician/stethIOlist`);
    }

  }

  // console.log('state.stethIOReview>', stethIOReview);
  // console.log('ID, state:', id, state);
  // console.log('isLoading:', isLoading);
  // console.log('patientData:', patientData);
  // console.log('recordingSample:', recordingSample);
  // console.log('reviewDetail.notes:', note);
  // console.log('reviewSubmittingError:', reviewSubmittingError);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(showToastHandler(false));
  };


  return (
    <React.Fragment>
      {isLoading && (
        <Box className={`${classes.container} ${classes.loadingContainer}`}>
          <Box>
            <Typography>Please Wait, Loading Patient Details...</Typography>
          </Box>
        </Box>
      )}
      {!error && patientData && Object.keys(patientData).length > 0 && !isLoading && (
        <Box className={classes.container}>
          {showToaster && !reviewSubmittingError && (
            <Box>
              <Toast
                open={showToaster}
                closeHandler={() => handleClose()}
                alertMessage={`Review has been successfully submitted.`}
                severity="success"
              />
            </Box>
          )}
          {showToaster && reviewSubmittingError && (
            <Box>
              <Toast
                open={showToaster}
                closeHandler={() => handleClose()}
                alertMessage={reviewSubmittingError}
                severity="error"
              />
            </Box>
          )}
          <Grid container>
            <Grid item className={classes.topMargin}>
              <Box style={{ marginBottom: "0.1rem" }}>
                <MuiTypography
                  fontSize="24px"
                  fontFamily={fontFamily}
                  lineHeight="28px"
                  letterSpacing="0.25px"
                  fontWeight={500}
                  color={black}
                  textTransform="capitalize"
                >
                  {`${patientData.lastName}, ${patientData.firstName}`}
                </MuiTypography>
              </Box>
              <BottomLineTitle />
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
              <Box className={classes.divider}></Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <EkgPatientInformation
                dob={patientData.dob}
                sex={patientData.sex === "M" ? "Male" : "Female"}
                weight={patientData.weight}
                height={patientData.height}
                language={patientData.language}
                symptoms={
                  patientData.symptoms
                    ? patientData.symptoms
                      .reduce((str, ele) => (str += ele + ", "), "")
                      .slice(0, -2)
                    : ""
                }
                patientnotes={
                  patientData.patientNotes ? patientData.patientNotes : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Spectrogram
                isLoading={wavFileLoading}
                error={wavFileError}
                fileURL={stethIOWavFileURL}
              />
            </Grid>
            <Grid item xs={3}>
              <StethIOReviewForm
                state={state}
                reviewDetail={reviewDetail}
                submitReview={(formData) => submitStethIOReview(formData)}
                cancelReview={(note) => cancelStehIOReview(note)}
              />
            </Grid>
          </Grid>
          <PatientGraphInfo
            reportId={patientData.reportId}
            interval={patientData.interval}
            interpretation={getEKGAlgorithmDeterminationDisplayText(
              patientData.interpretation,
              patientData.algorithmPackage,
              +patientData.heartRate
            )}
            bpm={patientData.heartRate}
            recordedAt={patientData.recordedAt}
          />
          {recordingSample && (
            <EkgCharts
              recording={recordingSample}
              is6l={Object.keys(recordingSample.samples).length === 6}
              duration={patientData?.duration}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  )
}

export default StethIOReview