import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  stethWorkList: [],
  isLoading: false,
  totalCount: 0,
  error: false,
  overdueCount: 0,
};

export const error = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};

const emptyData = (state) => {
  return updateObject(state, {
    stethWorkList: [],
    totalCount: 0,
  });
};

const getData = (state, action) => {
  return updateObject(state, {
    stethWorkList: action.payload.result,
    totalCount: action.payload.totalCount,
  });
};

const getOverdueCount = (state, action) => {
  return updateObject(state, {
    overdueCount: action.payload,
  });
};

const isFetching = (state, action) => {
  return updateObject(state, {
    isLoadingData: action.payload,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STETHIO_WORKLIST.SUCCESS:
      return getData(state, action);
    case types.STETHIO_OVERDUE_COUNT.SUCCESS:
      return getOverdueCount(state, action);
    case types.STETHIO_WORKLIST.FETCHING:
      return isFetching(state, action);
    case types.RESET_DATA:
      return emptyData(state, action);
    case types.STETHIO_WORKLIST.ERROR:
    case types.STETHIO_OVERDUE_COUNT.ERROR:
      return error(state, action);
    default:
      return state;
  }
};

export default reducer;
