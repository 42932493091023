import React from 'react'
import Chip from '@mui/material/Chip';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { KcTable } from '../../Components/Common/kc-table'

import {
  SUPPORTED_LOCALE,
  LOCALE_DEFAULT_TEXT
} from '../../Components/Common/kc-table'

import { CONST_VARIANT } from '../../Components/Common/kc-table/constants'

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: "#067F6F", // |'#8a2be2' '#1d8c5e' | '#067F6F'      |  blue - '#6387C5'
    },
    custom: {
      light: "#F1F1F1",
      main: "#000",
      dark: "#1E1E1E",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
  },
});

const StethIOList = ({
  data = [],
  getSelectedData,
}) => {

  const getStatusCases = (status) => {
    let retString = 'default'
    switch (status) {
      case 'OVERDUE':
        retString = 'error'
        break;
      case 'IN PROGRESS':
        retString = 'primary'
        break;
      case 'NOT STARTED':
        retString = 'primary'
        break;
      case 'COMPLETED':
        retString = 'success'
        break;
      case 'ERROR':
        retString = 'warning'
        break;

      default:
        break;
    }

    return retString
  }

  // Grid columns
  const columns = [
    {
      field: 'firstName', headerName: 'Name',
      minWidth: 320,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.firstName},{params.row.lastName}
          </div>)
      },
    },
    { field: 'reportId', headerName: 'ReportID', minWidth: 100, flex: 1 },
    { field: 'timeRequested', headerName: 'Time Requested', minWidth: 240, flex: 1 },
    {
      field: 'status', headerName: 'Status', minWidth: 140, flex: 1,
      renderCell: (params) => {
        // console.log('params', params);
        let statusVarint = getStatusCases(params.value)
        return <Chip label={params.value} color={statusVarint} variant="outlined" />
      },
    }
  ]

  const LOCALE = {
    ...LOCALE_DEFAULT_TEXT.enUS,
    noRowsLabel: 'All Settled ...',
  }

  const handleRowClick = (param) => {
    getSelectedData(param.row)
  }


  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: 600, width: '100%' }}>
        <KcTable
          initialState={{
            sorting: {
              sortModel: [{ field: '_active', sort: 'desc' }],
            },
          }}
          getRowId={(row) => `${row.id}${Math.random()}`}
          rows={data}
          columns={columns}
          defaultLocaleLanguage={SUPPORTED_LOCALE.English}
          defaultLocaleText={LOCALE}
          pagination
          layout={CONST_VARIANT.KC_DEFAULT}
          disableSelectionOnClick
          // disableDensitySelector
          onRowClick={handleRowClick}
          autoHeight={data.length <= 10}
        />
      </div>
    </ThemeProvider>
  )
}

export default StethIOList