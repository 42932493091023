import * as types from "./types";
import { getToken } from "../../Auth/AuthUtilities";
import { CrpAPI, GoAPI } from "../../Axios/axios";

export const showToastHandler = (isShow) => {
  return {
    type: types.SUBMIT_STETH_REVIEW.TOASTER_SHOW,
    payload: isShow,
  };
};

const errorData = (status) => {
  return {
    type: types.STETH_REVIEW.ERROR,
    payload: status,
  };
};

const errorWavData = (status) => {
  return {
    type: types.STETHIO_WAV_FILE.ERROR,
    payload: status,
  };
};

const isFetching = (status) => {
  return {
    type: types.STETH_REVIEW.FETCHING,
    payload: status,
  };
};

const isFetchingWav = (status) => {
  return {
    type: types.STETHIO_WAV_FILE.FETCHING,
    payload: status,
  };
};

const isUpdatingStatus = (status) => {
  return {
    type: types.STETHIO_STATUS_UPDATE.UPDATING,
    payload: status,
  };
};

const setIsSubmittingReview = (status) => {
  return {
    type: types.SUBMIT_STETH_REVIEW.SUBMITTING,
    payload: status,
  };
};

const setUpdateStatusSuccess = (status) => {
  return {
    type: types.STETHIO_STATUS_UPDATE.SUCCESS,
    payload: status,
  };
};

const errorReviewSubmit = (status) => {
  return {
    type: types.SUBMIT_STETH_REVIEW.ERROR,
    payload: status,
  };
};

const errorUpdateStatus = (status) => {
  return {
    type: types.STETHIO_STATUS_UPDATE.ERROR,
    payload: status,
  };
}

const getStethIOWavFileURL = (id) => {
  return (dispatch) => {
    dispatch(isFetchingWav(true));
    GoAPI.get(
      `/i/v1/steth/recording/file/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(isFetchingWav(false));
          dispatch({
            type: types.STETHIO_WAV_FILE.SUCCESS,
            payload: res.data.url,
          });
        }
      })
      .catch((err) => {
        dispatch(isFetchingWav(false));
        dispatch(errorWavData(err))
      });
  };
}

export const getStethIOReviewData = (id, state = false) => {
  const COMPLETED = state === 'COMPLETED'
  return (dispatch) => {
    dispatch(isFetching(true));
    CrpAPI.get(
      `steth/patientDetail/${id}?complete=${COMPLETED}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(isFetching(false));
          dispatch({
            type: types.STETH_REVIEW.SUCCESS,
            payload: res.data,
          });
          if (res.data && res.data.data.patientDetail) {
            dispatch(getStethIOWavFileURL(res.data.data.patientDetail.recordingPublicId))
          }
        }
      })
      .catch((err) => {
        dispatch(isFetching(false));
        dispatch(errorData(err))
      });
  };
};

export const stethIOReviewSubmit = (reviewData, history) => {
  return (dispatch) => {
    dispatch(setIsSubmittingReview(true));
    dispatch(errorReviewSubmit(""));
    CrpAPI.post(`steth/review`, reviewData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.SUBMIT_STETH_REVIEW.SUCCESS,
            payload: true,
          });
          dispatch(showToastHandler(true));
          dispatch(setIsSubmittingReview(false));
          history.push(`/clinician/stethIOlist`);
        }
      })
      .catch((err) => {
        dispatch(showToastHandler(true));
        dispatch(errorReviewSubmit(err));
        dispatch(setIsSubmittingReview(false));
      });
  };
};

export const stethIOUpdateStatus = (id, state, history, completed = false) => {
  return (dispatch) => {
    dispatch(isUpdatingStatus(true));
    CrpAPI.put(`steth/status/${id}/${state}`, {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.STETHIO_STATUS_UPDATE.SUCCESS,
            payload: true,
          });
          if (state === 'inProgress') {
            history.push(`/clinician/stethIO/review/${id}/${state}`);
          }
          else if (state === 'cancel') {
            history.push(`/clinician/stethIOlist`);
          }
          dispatch(setUpdateStatusSuccess(true))
          dispatch(isUpdatingStatus(false))
        }
      })
      .catch((err) => {
        dispatch(errorUpdateStatus(err))
        dispatch(isUpdatingStatus(false));
      });
  };
};