
// STATE
export const STETHIO_WORKLIST = {
  SUCCESS: 'STETHIO_WORKLIST.SUCCESS',
  ERROR: 'STETHIO_OVERDUE_COUNT.ERROR',
  FETCHING: 'STETHIO_WORKLIST.FETCHING'
}

export const STETHIO_OVERDUE_COUNT = {
  SUCCESS: 'STETHIO_OVERDUE_COUNT.SUCCESS',
  ERROR: 'STETHIO_OVERDUE_COUNT.ERROR'
}

export const FETCHING = 'FETCHING'
export const ACTIVE = 'ACTIVE'
export const RESET_DATA = 'RESET_DATA'
