import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Box, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import Badge from '@mui/material/Badge';

import MuiTypography from "../../Components/Shared/Typography/MuiTypography";
import BottomLineTitle from "../../Components/Shared/Typography/BottomLineTitle";
import TabPanel from "../../Components/TabPanel/TabPanel";
import { useStyles } from "./StethIOListStyles";
import { black } from "../../consts/colors";
import { fontFamily } from "../../Assets/styles/commonStyles";

// import custom component
import StethIOList from "../../Components/StethIOList/StethIOList";

// import Action
import { getStethIOWorklistData } from "../../Store/StethIOList/StethIOListActions";
//stethIOReviewSubmit
import { stethIOUpdateStatus } from "../../Store/StethIOReview/StethIOReviewAction";

// import const
import { PAGE_LIMIT } from "../../consts/pageLimit";

const StethIOWorkList = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [worklistData, setWorklistData] = useState([])

  const stethIOListData = useSelector((state) => state.stethIOList);

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(getStethIOWorklistData(PAGE_LIMIT, 0, "", "INCOMING"));
  }, [dispatch]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setWorklistData([])
    dispatch(getStethIOWorklistData(PAGE_LIMIT, 0, "", newValue === 1 ? 'COMPLETED' : 'INCOMING'));
  };

  useEffect(() => {
    if (stethIOListData && stethIOListData.stethWorkList && stethIOListData.stethWorkList.length) {
      setWorklistData(stethIOListData.stethWorkList)
    }
  }, [stethIOListData])

  const getCountLabel = (tab) => {
    let count = 0
    if (stethIOListData.stethWorkList.length) {
      if (tab === 'INCOMING' && value === 0) {
        count = stethIOListData.totalCount
      } else if (tab === 'COMPLETED' && value === 1) {
        count = stethIOListData.totalCount
      }
    }
    return (
      <React.Fragment>
        <Badge badgeContent={count} color="success">
          {tab}
        </Badge>
      </React.Fragment>
    )
  }

  const handleSelectedData = (data, state) => {
    if (state === 'COMPLETED') {
      history.push(`/clinician/stethIO/review/${data.id}/${state}`)
    } else {
      dispatch(stethIOUpdateStatus(data.id, 'inProgress', history))
    }
  }

  return (
    <Box className={classes.container}>
      <Grid>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item style={{ marginTop: "2.5rem" }}>
            <MuiTypography
              fontSize="24px"
              fontFamily={fontFamily}
              lineHeight="28px"
              letterSpacing="0.25px"
              fontWeight={500}
              color={black}
            >
              {t("StethIO WorkList")}
            </MuiTypography>
            <BottomLineTitle />
          </Grid>
        </Grid>
        <Box m={2} />
        <Box m={2} />
        <Box>
          <Tabs
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            value={value}
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            <Tab
              label={getCountLabel('INCOMING')}
            // onClick={incomingClickHandler}
            // disabled={isLoadingData && tabState !== 1}
            />
            <Tab
              label={getCountLabel('COMPLETED')}
            // onClick={completedClickHandler}
            // disabled={isLoadingData && tabState !== 2}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div>
              {worklistData && worklistData.length ?
                <StethIOList
                  data={worklistData.length ? worklistData : []}
                  getSelectedData={(d) => handleSelectedData(d)}
                />
                :
                <Typography>Please Wait, Loading Worklist Data...</Typography>
              }
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              {!stethIOListData.isLoadingData && worklistData ?
                <StethIOList
                  data={worklistData.length ? worklistData : []}
                  getSelectedData={(d) => handleSelectedData(d, 'COMPLETED')}
                />
                :
                <Typography>Please Wait, Loading Worklist Data...</Typography>
              }
            </div>
          </TabPanel>
        </Box>
      </Grid>
    </Box>
  );
};

export default StethIOWorkList;
