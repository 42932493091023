import React, { useEffect, useState } from 'react';
import { Typography } from "@material-ui/core";
import AudioEngine from "spectrogram-drawer";
import Button from '@mui/material/Button';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import LinearProgress from '@mui/material/LinearProgress';

// import css
import './Spectrogram.css';

const Spectrogram = ({ isLoading, error, fileURL }) => {
  const [audioEngine, setAudioEngine] = useState(null)
  const [engineStatus, setEngineStatus] = useState(false)
  const [SKD_KEY] = useState("va1vuCRG3fGDGB2ddGwjqQ==")
  const [selectedAudioFileSource, setAudioFileSource] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [fileBufferLoading, setFileBufferLoading] = useState(false)
  const [fileLoaded, setFileLoad] = useState(false);

  const initHeartData = async () => {
    console.log('Initializing StethIO AudioEngine ...')
    let a = await AudioEngine.initAudioEngine("canvasID", SKD_KEY)
    setAudioEngine(a);
    setAudioContext(new AudioContext())
  };

  useEffect(() => {
    initHeartData()
  }, [])

  useEffect(() => {
    if (fileURL !== undefined && fileURL !== null) {
      // console.log('fileURL Received :', fileURL);
      loadFileURL(fileURL)
    }
  }, [fileURL])

  // set the URL
  const loadFileURL = (fileURL) => {
    setAudioFileSource(fileURL)
    setEngineStatus(true)
  }

  // init audio Engine
  const startAudioEngine = () => {
    console.log('Starting AudioEngine...');
    setFileBufferLoading(true)
    audioEngine.startIt(false).then((res) => {
      audioEngine.loadOpusDecoder();
    })
      .catch((err) => {
        console.log('Start AudioEngine error: ', err);
      });
  }

  const getPlay = () => {
    console.log('Start Playing ...');
    // console.log('Audio Engine:  ', audioEngine);
    // console.log('audioContext:  ', audioContext);
    // console.log('File URL: ', selectedAudioFileSource);
    fetch(selectedAudioFileSource)
      .then(response => {
        startAudioEngine()
        // console.log('File URL Response: ', response);
        return response.arrayBuffer()
      })
      .then(arrayBuffer => {
        // console.log('Returned ArrayBuffer:  ', arrayBuffer);
        setFileBufferLoading(false)
        setFileLoad(true)
        return audioContext.decodeAudioData(arrayBuffer)
      }).catch(err => {
        console.log('Returned ArrayBuffer Error:  ', err);
      })
      .then(audioBuffer => {
        // console.log('audioBuffer: ', audioBuffer);
        audioEngine.testAudioInput(audioBuffer.getChannelData(0));
      });
  }

  const getCurrentState = () => {
    let returnStr = 'Initialized'
    if (selectedAudioFileSource !== null) {
      returnStr = 'File URL Received'
    }
    if (fileBufferLoading) {
      returnStr = 'Starting AudioEngine ...'
    }
    if (fileLoaded) {
      returnStr = 'Audio Wav File Loaded'
    }
    return returnStr
  }

  return (
    <div className='spectrogram_drawer'>
      <div className='container'>
        <article>
          Current State : &nbsp;
          {isLoading ?
            <Typography>Please Wait, Loading Patient Details...</Typography>
            :
            <span style={{ color: selectedAudioFileSource == null ? 'red' : 'green' }}>
              <b>{getCurrentState()}</b>
            </span>
          }
        </article>
        <br />
        <canvas id="canvasID" style={{ border: '1px solid' }}></canvas>
        <br />
        <br />
        <div className='bottom_panel'>
          {/* <Stack direction="row" spacing={2} alignItems="center"> */}
          {/* <Button
            variant="outlined"
            disabled={engineStatus}
            onClick={() => { startAudioEngine() }}>
            Start Engine
          </Button> */}
          &nbsp;
          &nbsp;
          <Button
            variant="outlined"
            startIcon={<PlayCircleFilledWhiteIcon />}
            disabled={!engineStatus}
            onClick={() => { getPlay() }}>
            Loud Me
          </Button>
        </div>
        <br />
        {fileBufferLoading && <LinearProgress />}
      </div>
    </div>
  )
}

export default Spectrogram