import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  reviewData: {},
  isLoading: false,
  error: false,
  stethIOWavFileURL: undefined,
  wavFileIsLoading: false,
  wavFileError: false,
  stethIOReviewsubmit: undefined,
  isSubmittingReview: false,
  reviewSubmittingError: false,
  showToaster: false,
  statusUpdating: false,
  statusUpdateSuccess: false,
  statusUpdateError: false,
};
const resetData = (state, action) => {
  return updateObject(state, {
    reviewData: {},
    isLoading: false,
    error: false,
    stethIOWavFileURL: undefined,
    wavFileIsLoading: false,
    wavFileError: false,
    stethIOReviewsubmit: undefined,
    isSubmittingReview: false,
    reviewSubmittingError: false,
    showToaster: false,
    statusUpdating: false,
    statusUpdateSuccess: false,
    statusUpdateError: false,
  });
}

const error = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};

const errorWavFile = (state, action) => {
  return updateObject(state, {
    wavFileError: action.payload,
  });
};

const errorStethIOReviewSubmit = (state, action) => {
  return updateObject(state, {
    reviewSubmittingError: action.payload.message,
  });
};

const stethIOStatusUpdateError = (state, action) => {
  return updateObject(state, {
    statusUpdateError: action.payload.message,
  });
};

const getStethIOReviewData = (state, action) => {
  return updateObject(state, {
    reviewData: action.payload.data,
  });
};

const getStethIOWavFile = (state, action) => {
  return updateObject(state, {
    stethIOWavFileURL: action.payload,
  });
};

const submitStethIOReviewSuccess = (state, action) => {
  return updateObject(state, {
    isSubmittingReview: false,
    stethIOReviewsubmit: action.payload,
  });
};

const stethIOStatusSuccess = (state, action) => {
  return updateObject(state, {
    statusUpdateSuccess: action.payload,
  });
};

const isFetching = (state, action) => {
  return updateObject(state, {
    isLoading: action.payload,
  });
};

const isWavFetching = (state, action) => {
  return updateObject(state, {
    wavFileIsLoading: action.payload,
  });
};

const stethIOStatusUpdating = (state, action) => {
  return updateObject(state, {
    statusUpdating: action.payload,
  });
};


const isSubmitingStethIOReview = (state, action) => {
  return updateObject(state, {
    isSubmittingReview: action.payload,
  });
};

const showToaster = (state, action) => {
  return updateObject(state, {
    showToaster: action.payload,
  });
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STETH_REVIEW.SUCCESS:
      return getStethIOReviewData(state, action);
    case types.STETH_REVIEW.FETCHING:
      return isFetching(state, action);
    case types.STETH_REVIEW.ERROR:
      return error(state, action);

    case types.STETHIO_WAV_FILE.SUCCESS:
      return getStethIOWavFile(state, action);
    case types.STETHIO_WAV_FILE.FETCHING:
      return isWavFetching(state, action);
    case types.STETHIO_WAV_FILE.ERROR:
      return errorWavFile(state, action);

    case types.SUBMIT_STETH_REVIEW.SUCCESS:
      resetData(state, action)
      return submitStethIOReviewSuccess(state, action);
    case types.SUBMIT_STETH_REVIEW.SUBMITTING:
      return isSubmitingStethIOReview(state, action);
    case types.SUBMIT_STETH_REVIEW.ERROR:
      return errorStethIOReviewSubmit(state, action);
    case types.SUBMIT_STETH_REVIEW.TOASTER_SHOW:
      return showToaster(state, action);

    case types.STETHIO_STATUS_UPDATE.SUCCESS:
      return stethIOStatusSuccess(state, action);
    case types.STETHIO_STATUS_UPDATE.UPDATING:
      return stethIOStatusUpdating(state, action);
    case types.STETHIO_STATUS_UPDATE.ERROR:
      return stethIOStatusUpdateError(state, action);

    default:
      return state;
  }
};

export default reducer;
