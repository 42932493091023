import React, { useState } from "react";

// import axios from "axios";
import { Box } from "@material-ui/core";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const StethIOReviewForm = ({ state, reviewDetail, submitReview, cancelReview }) => {

  const [note, setNote] = useState('')
  const [normalHeart, setNormalHeart] = useState(false)
  const [normalLung, setNormalLung] = useState(false)
  const [normalIntestine, setNormalIntestine] = useState(false)

  // && note == '' && reviewDetail && (reviewDetail.notes !== '' || reviewDetail.notes !== null)
  if (state === 'COMPLETED') {
    // console.log('reviewDetail.notes:', reviewDetail, note);
    setNote(reviewDetail.notes)
    setNormalHeart(reviewDetail.normalHeart)
    setNormalIntestine(reviewDetail.normalIntestine)
    setNormalLung(reviewDetail.normalLung)
  }

  const handleCheckChange = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    switch (name) {
      case 'normalHeart':
        setNormalHeart(checked)
        break;
      case 'normalLung':
        setNormalLung(checked)
        break;
      case 'normalIntestine':
        setNormalIntestine(checked)
        break;

      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <FormGroup>
        <TextField
          id="comment-text"
          label="Reading Notes"
          fullWidth
          multiline
          value={note}
          disabled={state === 'COMPLETED'} // && reviewDetail.notes !== ''
          rows={12}
          onChange={(e) => setNote(e.target.value)}
        // defaultValue="Default Value"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '3vh'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={normalHeart}
                onChange={handleCheckChange}
                name="normalHeart"
                disabled={state === 'COMPLETED'} // && reviewDetail.notes !== ''
              />
            }
            label="Normal Heart"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={normalLung}
                onChange={handleCheckChange}
                name="normalLung"
                disabled={state === 'COMPLETED'} // && reviewDetail.notes !== ''
              />
            }
            label="Normal Lung"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={normalIntestine}
                onChange={handleCheckChange}
                name="normalIntestine"
                disabled={state === 'COMPLETED' && reviewDetail.notes !== ''}
              />
            }
            label="Normal Intestine"
          />
        </Box>
      </FormGroup>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          variant="contained"
          onClick={() => submitReview({ note, normalHeart, normalLung, normalIntestine })}
          disabled={note === '' || state === 'COMPLETED'}
        >Submit</Button>
        <Button
          variant="contained"
          onClick={() => cancelReview(reviewDetail.notes)}
        >Cancel</Button>
      </Stack>
    </Box>
  )
}

export default StethIOReviewForm